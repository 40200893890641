import React from 'react'

export default function NotFound() {
    return (
        <div>
            <h1 className='text-danger display-4'>Oops!</h1>
            <p className='fs-4'>Something went wrong</p>
        </div>
    )
}
